let Testimonies = [
    {
        Testimony: `After employing the services of 906, my tech department have learnt a great deal on how to properly manage our data integrity and security.` ,
        Quoted: `Francis Okoye`  
    },
    {
        Testimony: `With 906 cloud services, my organization has successfully been able to migrate to the cloud and they have helped improve our departmental collaboration across the board.` ,
        Quoted: `Gbenga Coker`     
    },
    {
        Testimony: `During the heat of the lock down 906 came through in improving the ability of my staff and I to effectively collaborate remotely, seemed like we never left the office.` ,
        Quoted: `Sodiqu Abubakar`    
    },
    {
        Testimony: `Professionalism, Efficiency and impeccable customer care, some of the words I can use to describe my dealings with 906 global.` ,
        Quoted: `Frank B. Asinobi`   
    },
    {
        Testimony: `The project on Azure AD done for Wemabod by 906 Global has made a significant difference to our business with the visibility it offers.` ,
        Quoted: `Adeoyo Adeniji`     
    },
]

export default Testimonies;