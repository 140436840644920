
// import { AppService, CloudService, Collaboration, Security, Training } from '../../Assets/Services';
import {default as AppService} from '../../Assets/Services/AppServices/header.webp'
import {default as CloudService} from '../../Assets/Services/CloudServices/header.jpg'
import {default as Collaboration} from '../../Assets/Services/Collaboration/header.jpg'
import {default as Training} from '../../Assets/Services/Training/header.jpg'
import {default as Security} from '../../Assets/Services/Security/header.jpg'
import {default as CRM} from '../../Assets/Services/CRM/header.jpg'

let serviceContent = [
    {
     index: 1,
     header: "Cloud \n Services",
     image: CloudService,
     Content: ` Cloud services and business mobility are paramount in today’s business landscape and that is why we maintain your services on a private network and the hardware and software dedicated solely to your organization making it easier for your organization to tailor resources to your specific requirements, which would provide you with flexibility, control and stability. Deploying our azure cloud service to your organization helps you innovate and evolve with purpose towards growth, realizing cost saving, efficient and flexible cloud computing processes.`},
    {
     index: 2,
     header: "Security",
     image: Security ,
     Content: `Ease of work is essential and therefore we provide Microsoft enterprise mobility security to aid efficient and intelligent mobility management and security which helps both secure and protect the employees and the organization, giving you the freedom to work in new and convenient ways. 
     With the deployment of our Sophos firewall and endpoint , we embolden your network to identify users, detect network infection sources and autonomously limit infection to outer network resource and peripherals, our Sophos software integrates malicious traffic detection and real time threat intelligence to prevent, detect, sandbox and remediate threats and infections.
     While offering cloud services we ensure native security for your cloud resources by deploying Checkpoint CloudGuard with advanced threat prevention for all our assets and workloads in both public, private, hybrid or multi cloud infrastructure thus making available unified security and automating seamless and tier 1 security everywhere.
     `},
    {
     index: 3,
     header: "App Services",
     image: AppService,
     Content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec mattis ultrices interdum. Mauris ultrices nisi purus, in blandit leo placerat ac. Nulla finibus leo eu ligula auctor vehicula. Etiam non sollicitudin urna. Sed a quam ultrices, dapibus risus nec, posuere quam. Donec a luctus nibh. Donec nisi enim, varius porttitor dictum eu, congue in risus. Donec sed metus urna. Donec auctor porta felis imperdiet cursus. Donec felis enim, vehicula nec euismod ut, interdum et ligula.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec mattis ultrices interdum. Mauris ultrices nisi purus, in blandit leo placerat ac. Nulla finibus leo eu ligula auctor vehicula. Etiam non sollicitudin urna. Sed a quam ultrices, dapibus risus nec, posuere quam. Donec a luctus nibh. Donec nisi enim, varius porttitor dictum eu, congue in risus. Donec sed metus urna. Donec auctor porta felis imperdiet cursus. Donec felis enim, vehicula nec euismod ut, interdum et ligula.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec mattis ultrices interdum. Mauris ultrices nisi purus, in blandit leo placerat ac. Nulla finibus leo eu ligula auctor vehicula. Etiam non sollicitudin urna. Sed a quam ultrices, dapibus risus nec, posuere quam. Donec a luctus nibh. Donec nisi enim, varius porttitor dictum eu, congue in risus. Donec sed metus urna. Donec auctor porta felis imperdiet cursus. Donec felis enim, vehicula nec euismod ut, interdum et ligula.`},
    {
     index: 4,
     header: "Collaboration",
     image: Collaboration,
     Content: `Using SharePoint, we allow your teams seamlessly and dynamically share and manage content for projects with bespoke team websites where knowledge content and applications can be shared for easier and streamlined collaboration across the organization, while with our Microsoft Exchange Servers we help your organization improve productivity and reduce “RE” mails by introducing enterprise class tools for productivity.
     With Office 365, you would not need another application to collaborate, empowering your employees with a cloud-based suite and integrating all Microsoft online applications into a simple and easy to use cloud service, collaboration and productivity improves inevitably, after which we then automate processes to ensure you focus on what is important. With BPA we help you automate mundane processes and function to free up time and manpower for more productive and growth driven activities and enabling employees to build new capabilities via our applications.
     `},
    {
     index: 5,
     header: "Training",
     image: Training ,
     Content: `Change is the only constant, at 906 we believe your team should continuously evolve, adapt and conquer, to do that your team needs to be at the top level.
     With our training and support services you can be rest assured that employee competency and skill sets would be maintained at the highest levels with quality IT training and expert consultation services to keep your team and workforce at their sharpest of wits, enabling you pursue job enhancements, promotion, increased motivation and job satisfaction.
     `},
    {
     index: 6,
     header: "CRM",
     image: CRM,
     Content: `Increasing your profitability and bottom line by streamlining processes that clog the wheel of effective sales, marketing and customer service, while allowing you focus on creating and maintain the best relationship between you and your customers. Our aim is to improve your interaction and business process with your customer thereby improving business growth and productivity.
     `}
]

export default serviceContent;

